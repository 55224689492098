// var stickyHeader = false;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

var stickyHeader = true;
